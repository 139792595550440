<template>
  <div>
    <title-nav :title="'1:1문의답변'" :nav="'CS / 1:1문의답변'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="selectedQAStatus"
              :options="radioQAStatus"
              :aria-describedby="ariaDescribedby"
              name="radio-inline"
              @change="changeQAStatus"
            ></b-form-radio-group>
          </b-form-group>
        </div>
        <div class="card-body p-0">
          <b-table
            striped
            hover
            bordered
            class="table-sm text-sm-center"
            :borderless="borderless"
            :fixed="fixed"
            :no-border-collapse="noCollapse"
            :items="items"
            :fields="fields"
            :head-variant="headVariant"
            :busy="isBusy"
            show-empty
          >
            <!-- 질의 유형 -->
            <template v-slot:cell(reg_dt)="data">
              <span>{{ data.value | dateFilter }}</span>
            </template>
            <template v-slot:cell(USER_SN)="data">
              <router-link :to="{ name: 'detail', params: { usersn: data.value } }">{{
                data.value
              }}</router-link>
            </template>

            <template v-slot:cell(title)="data">
              <a href="#" v-b-modal.modal-1 @click="sendInfo(data.item.qna_sn)">
                <b-button variant="link">
                  {{ data.value }}
                </b-button>
              </a>
            </template>

            <!-- 질의 유형 -->
            <template v-slot:cell(qna_type)="data">
              <span>{{ getCodeName(data.value) }}</span>
            </template>

            <template v-slot:cell(point)="data">{{ data.value | commaFilter }}</template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty>
              <h4>데이터가 없습니다.</h4>
            </template>
          </b-table>
        </div>
        <div class="card-footer clearfix">
          <b-pagination
            v-model="page"
            :total-rows="records"
            :per-page="rowPerPage"
            @change="setPage"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </b-container>

    <div>
      <b-modal
        id="modal-1"
        size="lg"
        title="상세 및 답변"
        @ok="handleOk_answer"
        ok-title="수정/확인"
        cancel-title="닫기"
      >
        <form ref="form" @submit.stop.prevent="handleOk_answer">
          <table class="mx-2 mb-2" border="1" width="100%">
            <colgroup>
              <col width="20%" />
              <col width="80%" />
            </colgroup>
            <tbody>
              <tr>
                <td class="td-title">회원 이메일</td>
                <td class="td-data">{{ itemsDetail.email }}</td>
              </tr>
              <tr>
                <td class="td-title">제목</td>
                <td class=" td-data ">
                  {{ itemsDetail.title }}
                </td>
              </tr>
              <tr>
                <td class="td-title">내용</td>
                <td class="td-data">{{ itemsDetail.contents }}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <b-form-textarea
                    id="textarea-auto-height"
                    placeholder="답변을 입력해주세요."
                    rows="3"
                    max-rows="10"
                    v-model="answer"
                  ></b-form-textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { qna_list, qna_detail, qna_set_answer, getQNACode } from '@/api/cs'

export default {
  name: 'QnAList',
  data() {
    return {
      page: 1,
      rowPerPage: 20,
      records: 0,
      isBusy: false,
      selectedQAStatus: 'ALL',
      radioQAStatus: [
        { value: 'ALL', text: '전체' },
        { value: 'NEED', text: '답변 필요' },
        { value: 'COMPLETE', text: '답변 완료' }
      ],
      fields: [
        {
          key: 'qna_sn',
          label: 'NO.',
          colType: 'span'
        },
        {
          key: 'reg_dt',
          label: '등록날짜',
          colType: 'span'
        },
        {
          key: 'user_sn',
          label: '고객번호',
          colType: 'span'
        },
        {
          key: 'admin_name',
          label: '담당자',
          colType: 'span'
        },
        {
          key: 'qna_type',
          label: 'QA 종류',
          colType: 'span'
        },
        {
          key: 'title',
          label: 'title',
          colType: 'span',
          tdClass: 'link-primary text-left'
        },
        {
          key: 'email',
          label: 'email',
          colType: 'span',
          tdClass: 'text-left'
        }
      ],
      items: [],
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark',
      QNA_CD: [],
      qna_sn: '',
      itemsDetail: '',
      answer: '',
      user_sn: 0,
      searchType: '',
      searchText: 'N'
    }
  },
  computed: {},
  watch: {},
  methods: {
    getCodeName(code) {
      const name = this.QNA_CD && this.QNA_CD.find(value => value.cd_value === code).cd_name_kr
      return name
    },
    async getList() {
      this.isBusy = true
      this.items = []
      const resp = await qna_list(this.page, this.rowPerPage, this.searchType, this.searchText)
      if (resp.code === '0000') {
        if (resp.data.list) {
          this.items = resp.data.list
          this.records = resp.data.totCnt
        }
      }
      this.isBusy = false
    },
    setPage(page) {
      this.page = page
      this.getList()
    },
    setCondition() {
      this.page = 1
      this.getList()
    },
    sendInfo(num) {
      this.qna_sn = num
      this.getDetail(num)
    },
    changeQAStatus(e) {
      console.log(e)
      if (e === 'NEED') {
        this.searchType = 'answer'
        this.searchText = 'N'
      } else if (e === 'COMPLETE') {
        this.searchType = 'answer'
        this.searchText = 'Y'
      } else {
        this.searchType = null
        this.searchText = null
      }
      this.getList()
    },
    async getDetail(num) {
      try {
        const resp = await qna_detail(num)
        if (resp.code === '0000') {
          this.itemsDetail = resp.data
          this.answer = resp.data.answer
          this.user_sn = resp.data.user_sn
        } else {
          alert(`error:${resp.code}`)
        }
      } catch (error) {
        console.error(error)
      }
    },
    handleOk_answer(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit_answer()
    },
    handleSubmit_answer() {
      qna_set_answer(this.qna_sn, this.answer, this.user_sn)
        .then(resp => {
          if (resp.code === '0000') {
            alert(`답변 완료`)
            this.$nextTick(() => {
              this.$bvModal.hide('modal-1')
              this.setCondition()
            })
          } else {
            alert(`code:${resp.code}`)
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    async initCode() {
      const resp = await getQNACode()
      if (resp.code === '0000') {
        this.QNA_CD = resp.data
      } else {
        alert(resp.message)
      }
    }
  },
  mounted() {
    this.initCode()
    this.getList()
  }
}
</script>

<style scoped>
table tbody tr .td-title {
  color: #fff;
  background-color: #212529;
  border-color: #383f45;
  padding-left: 10px;
}

table tbody tr .td-data {
  color: #000;
  background-color: #ffffff;
  border-color: #383f45;
  text-align: left;
  padding-left: 10px;
}
</style>
